import React from 'react'
import { useState } from 'react'
import { Button, Form, Spinner } from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker'
import { toast } from 'react-toastify'
import { acceptRejectedPayment, rejectAcceptedPayment } from '../../services/admin'
import CustomModal from './Modal'

const PaymentStatusModal = ({ show, setShow, reload, isReject }) => {

    console.log(show, "show")

    const [transactionId, settransactionId] = useState();
    const [transactionDate, settransactionDate] = useState();
    const [loading, setLoading] = useState(false)

    const handleRejectData = (e) => {
        settransactionId({ ...transactionId, [e.target.name]: e.target.value })
    }


    const handleAcceptRejectedPayment = async () => {
        setLoading(true)
        await acceptRejectedPayment(show, { transactionDate, adminPassword: transactionId.adminPassword, transactionId: transactionId.transactionId }).then((res) => {
            if (res.status === 200) {
                toast.success("Payment Accepted");
                setShow(undefined)
                reload();
                setLoading(false)
            }
        }).catch((err) => {
            toast.error("Unable to accept this payment");
            setLoading(false)
        })
    }

    const handleRejecteAccepetedPayment = async () => {
        setLoading(true)
        await rejectAcceptedPayment(show, transactionId).then((res) => {
            if (res.status === 200) {
                toast.success("Payment Accepted");
                setShow(undefined)
                reload();
                setLoading(false)
            }
        }).catch((err) => {
            toast.error("Unable to reject this payment");
            setLoading(false)
        })
    }


    return (
        <>
            {isReject === true ?
                <CustomModal
                    displayModal={show ? true : false}
                    onClose={() => setShow(undefined)}
                    removeClose={true}
                    modalBody={
                        <>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Admin Password</Form.Label>
                                <Form.Control type="text" name="adminPassword" placeholder="Enter  Password" onChange={handleRejectData} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Transaction Id</Form.Label>
                                <Form.Control type="text" name="transactionId" placeholder="Enter Transaction Id" onChange={handleRejectData} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Transaction Date</Form.Label>
                                <ReactDatePicker dateFormat="MMMM d, yyyy h:mm aa" className='form form-control' showTimeSelect selected={transactionDate} timeIntervals="1" onChange={(date) => settransactionDate(date)} />
                            </Form.Group>

                            <Button variant="primary" type="submit" className="mt-3 text-center" onClick={handleAcceptRejectedPayment} disabled={loading}>
                                Submit {loading && <Spinner animation="border" className="ms-1" />}
                            </Button>
                            <Button variant="danger" className="mt-3" onClick={() => setShow(undefined)} style={{ marginLeft: "10px" }}>Close</Button>
                        </>
                    }
                /> : <CustomModal
                    displayModal={show ? true : false}
                    onClose={() => setShow(undefined)}
                    removeClose={true}
                    modalBody={
                        <>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Admin Password</Form.Label>
                                <Form.Control type="text" name="adminPassword" placeholder="Enter  Password" onChange={handleRejectData} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Remarks</Form.Label>
                                <Form.Control type="text" name="remarks" placeholder="" onChange={handleRejectData} />
                            </Form.Group>
                            <Button variant="primary" type="submit" className="mt-3 text-center" onClick={handleRejecteAccepetedPayment} disabled={loading} >
                                Submit {loading && <Spinner animation="border" className="ms-1" />}
                            </Button>
                            <Button variant="danger" className="mt-3" onClick={() => setShow(undefined)} style={{ marginLeft: "10px" }}>Close</Button>
                        </>
                    }
                />}

        </>
    )
}

export default PaymentStatusModal