import React, {useState} from 'react';
import SubComponent from '../../components/SubComponent/SubComponent';
import PaymentBox from './PaymentBox';

function Payment() {
    let [selectedNav, setSelectedNav] = useState(0);
    const topNavMenu = [
        {
            title: "All",
            url: 'all',
            component: PaymentBox
        }
    ];
    


    return (
        <>
              <SubComponent topNavMenu={topNavMenu} selectedNav={selectedNav} setNav={(value)=>setSelectedNav(value)} /> 
        </>

    )
}

export default Payment
