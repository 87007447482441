import React, { useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { updateAcceptedPaymentStatus } from '../../services/admin';
import CustomModal from './Modal'




const PaymentAcceptModal = ({ show, setShow }) => {


    const [transactionId, settransactionId] = useState();
    const [transactionDate, settransactionDate] = useState();

    const handleAcceptedPayment = async () => {
        await updateAcceptedPaymentStatus(show, transactionId, transactionDate)
            .then((res) => {
                if (res.status === 200) {
                    toast.success("Update successfully");
                    setShow(undefined)
                }
            })
            .catch((err) => {
                toast.error(err.response);
            });
    }



    return (
        <>
            <CustomModal
                displayModal={show ? true : false}
                onClose={() => setShow(undefined)}
                removeClose={true}
                modalBody={
                    <>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Transaction Id</Form.Label>
                            <Form.Control type="text" name="remarks" placeholder="Enter Transaction Id" onChange={(e) => settransactionId(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Transaction Date</Form.Label>
                            <ReactDatePicker dateFormat="MMMM d, yyyy h:mm aa" className='form form-control' showTimeSelect selected={transactionDate} timeIntervals="1" onChange={(date) => settransactionDate(date)}  />
                        </Form.Group>

                        <Button variant="primary" type="submit" className="mt-3" onClick={handleAcceptedPayment}>
                            Submit
                        </Button>
                        <Button variant="danger" className="mt-3" onClick={() => setShow(undefined)} style={{ marginLeft: "10px" }}>Close</Button>
                    </>
                }
            />

        </>
    )
}

export default PaymentAcceptModal;