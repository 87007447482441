import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";

function XadmDropdown({ handlePaymentStatus }) {
  const [paymentStatus, setPaymentStatus] = useState("pending");



  return (
    <Dropdown>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        {(paymentStatus === "completed" && "Completed") ||
          (paymentStatus === "pending" && "Pending") || 
          (paymentStatus === "rejected" && "Rejected") }
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onSelect={()=>{setPaymentStatus("completed"); handlePaymentStatus("completed")}}>
          Completed
        </Dropdown.Item>
        <Dropdown.Item onSelect={()=>{setPaymentStatus("pending"); handlePaymentStatus("pending")}}>
          Pending
        </Dropdown.Item>
        <Dropdown.Item onSelect={()=>{setPaymentStatus("rejected"); handlePaymentStatus("rejected")}}>
          Rejected
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default XadmDropdown;
