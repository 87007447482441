const urls = {
  getUsers: "/admin/getUsers",
  getSubmittedUsers: "/admin/getSubmittedUsers",
  getUserProfile: "/admin/getUser/",
  getUserByStatus: "/admin/getUserByStatus",
  getUserStats: "/admin/getUserStats",
  getCenters: "/users/getCenterList",
  getUsersByCityChoices: "/admin/getUsersByCityChoices",
  sendMail: "/admin/sendGeneralEmail",
  getAllTransaction: "/admin/payment",
  updateTransactionForUser: "/admin/updateTransactionForUser",
  updateRejectedPaymentForUser: "/admin/payment/reject",
  getAllPaymentsByStatus: "/admin/payment/all",
  updateAcceptedPaymentStatus: "/admin/payment/accept",
  updateCandidateEmail: "/admin/updateCandidateEmail",
  acceptRejectedPayment: "/admin/payment/acceptRejectedPayment",
  rejectAcceptedPayment: "admin/payment/rejectAcceptedPayment"
};

export default urls;