import React, { useEffect, useState } from "react";

import { useParams, useHistory } from "react-router";

// views
import Home from "../Home/Home";
import UserData from "../UserData/UserData";

// components
import XadmSidebar from "../../components/Sidebar/Sidebar";
// Icons

// CSS
import "./Dashboard.css";
import "../../assets/css/form-components.css";

import { faPlusSquare, faHome,faSignOutAlt, faEnvelope, faMoneyCheck } from "@fortawesome/free-solid-svg-icons";
import Logout from "../Logout/Logout";
import SwitchComponent from "../../components/SwitchComponent/SwitchComponent";
import SendEmail from "../SendEmail/SendEmail";
import Payment from "../Payment/Payment";



export default function Dashboard (){

  const { menu } = useParams();

  let history = useHistory();

  const [selectedNav, setselectedNav] = useState(0)

  const sideMenudata = [
    // {
    //   title: "Dashboard",
    //   icon: faHome,
    //   url: "home",
    //   defaultSubUrl: "dash",
    //   component: Home,
    // },
    {
      title: "Candidates",
      icon: faPlusSquare,
      url: "user",
      defaultSubUrl: "all",
      component: UserData,
    },
    {
      title: "Send Email",
      icon: faEnvelope,
      url: "email",
      defaultSubUrl: "sendmail",
      component: SendEmail,
    },
    {
      title: "Payment",
      icon: faMoneyCheck,
      url: "payment",
      defaultSubUrl: "all",
      component: Payment,
    },
  ];

  function handler(value) {
    setselectedNav(value);
    history.push("/dashboard/"+ sideMenudata[value].url + "/" + sideMenudata[value].defaultSubUrl);
}

function onMount (){
  switch(menu){
      // case "home":{
      //     setselectedNav(0);
      //     break;
      // }
      case "user":{
          setselectedNav(0);
          break;
      }
      case "email": {
        setselectedNav(1);
        break;
      }
      case "payment": {
        setselectedNav(2);
        break;
      }
      default: {
          setselectedNav(0);
          history.push("/dashboard/user");
      }
  }
}
useEffect(()=>{
  onMount();
}, [])

  return (
    <div className="xadm-dashboard-bg">
      <XadmSidebar
        menuData={sideMenudata}
        handler={handler}
        selected={selectedNav}
      />
      <div className="xadm-main-area bg-bg-primary">
      <SwitchComponent component={sideMenudata[selectedNav].component} />
      </div>
    </div>
  );

}
