import makeRequest from "./makeRequest";
import urls from "./urls";

export async function getUsers(pageSize, pageNumber) {
  return await makeRequest(urls.getUsers + "?pageSize=" + pageSize + "&pageNumber" + pageNumber, 'get');
}

export async function getSubmittedUsers() {
  return await makeRequest(urls.getSubmittedUsers, 'get');
}

export async function getUserByStatus(status, search, page, count) {
  console.log(search, "from payload")
  return await makeRequest(urls.getUserByStatus + "?status=" + status + "&search=" + search + "&page=" + page + "&count=" + count, 'get')
}

export async function getUserProfile(userId) {
  return await makeRequest(urls.getUserProfile + userId, 'get');
}

export async function getUsersStats() {
  return await makeRequest(urls.getUserStats, 'get');
}

export async function getCitiesData() {
  return await makeRequest(urls.getCenters, 'get');
}

export async function getCandidatesByCenters(center1, center2) {
  return await makeRequest(urls.getUsersByCityChoices, 'post', { choice1: center1, choice2: center2 });
}

export async function sendMail(payload) {
  return await makeRequest(urls.sendMail, 'post', payload);
}

export async function updateTransactionForUser(user_id, paymentId, transactionId) {
  return await makeRequest(urls.updateTransactionForUser + "/" + user_id, 'post', { paymentId, transactionId });
}
export async function updateRejectedPaymentForUser(paymentId, remarks) {
  return await makeRequest(
    urls.updateRejectedPaymentForUser + "/" + paymentId,
    "put",
    { remarks }
  );
}
export async function updateAcceptedPaymentStatus(paymentId, transactionId, transactionDate) {
  return await makeRequest(
    urls.updateAcceptedPaymentStatus + "/" + paymentId,
    "put",
    { transactionId, transactionDate }
  );
};
export async function getAllPaymentsByStatus(status) {
  return await makeRequest(
    urls.getAllPaymentsByStatus + "?status=" + status,
    "get"
  );
}

export async function updateCandidateEmail(userId, email) {
  return await makeRequest(
    urls.updateCandidateEmail + "/" + userId,
    "put",
    {
      email: email
    }
  );
}

export async function getTransactionDetailsById(userId) {
  return await makeRequest(
    urls.getAllTransaction + "/" + userId,
    "get"
  );
}

export async function acceptRejectedPayment(userId, payload) {
  return await makeRequest(
    urls.acceptRejectedPayment + "/" + userId,
    "put", payload
  );
}

export async function rejectAcceptedPayment(userId, payload) {
  return await makeRequest(
    urls.rejectAcceptedPayment + "/" + userId,
    "put", payload
  );
}