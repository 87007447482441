import React, { useState } from "react";

// bootstrap
import { Container, Card, Row, Col, Form, Button } from "react-bootstrap";

import Auth from "../../Auth";
// components

// Icons

// CSS
import "./login.css";
import "../../assets/css/form-components.css";
import { toast } from "react-toastify";
// Other Imports
import loginSvg from "../../assets/icons/logo.png";
import axios from "axios";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");


  const onLoginClick = () => {
    axios({
      method: "post",
      url:
        process.env.REACT_APP_SERVER_IP +
        process.env.REACT_APP_API_VER +
        "/admin/login",
      data: {
        email: email,
        password: password,
        role: "Admin",
      },
    }).then(
      (res) => {
        if (res.status === 200) {
          toast.success("successful");
          sessionStorage.setItem("authKey", res.data);
          Auth.authenticate();
          props.history.push("/dashboard/user/all");
        } else {
          console.log("failed to login");
        }
      },
      (error) => {
        toast.error(error.message);
        console.log(error);
      }
    );
  };

  return (
    <div className="xadm-login">
      <Container fluid>
        <h2 className="text-left  font-weight-bold xadm-login-header">
          NIXADMIN
        </h2>

        <div>
          <Row className="justify-content-md-center  mt-4">
            <Col lg="8">
              <div className="xadm-login-img"></div>
            </Col>
            <Col lg="4">
              <Card
                className="p-3 xadm-card"
              >
                <h5 className="text-center font-weight-bold text-secondary">
                  Login
                </h5>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label className="text-muted xadm-input-label">
                    User Id
                  </Form.Label>
                  <Form.Control
                    className="xadm-input-text"
                    type="email"
                    placeholder="User ID"
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                  <Form.Label className="text-muted xadm-input-label">
                    Password
                  </Form.Label>
                  <Form.Control
                    className="xadm-input-text"
                    type="password"
                    placeholder="Password"
                    name="password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
                <Button variant="primary" type="submit" onClick={onLoginClick}>
                  Submit
                </Button>
              </Card>
            </Col>
            <Col xs lg="2" />
          </Row>
        </div>
      </Container>
      <Container fluid className="xadm-login-bg">
        <span className="text-white">ENIXM &copy;2021</span>
      </Container>
    </div>
  );
};
export default Login;
