import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Image } from 'react-bootstrap';

export default function ViewEduInfo({ educationInfo }) {

    const renderEducationData = (educationData) => {
        return (
            <div className="d-flex justify-content-between mt-4">
                <div>
                    <div>
                        <span> Degree Type: </span> <span className="font-weight-bold">{educationData.degree ? educationData.degree.toUpperCase() : "--"} </span>
                    </div>

                    <div>
                        <span> Year of Passing or Expected: </span> <span className="font-weight-bold">{String(educationData.year ? educationData.year : "--")} </span>
                    </div>

                    <div>
                        <span> College Name: </span> <span className="font-weight-bold">{String(educationData.institute ? educationData.institute : "--")} </span>
                    </div>
                    {educationData && educationData.type ? <div>
                        <span> Trade: </span> <span className="font-weight-bold">{String(educationData.type ? educationData.type : "--")} </span>
                    </div> : ""}
                    {educationData && educationData.rollNo ? <div>
                        <span> Roll No: </span> <span className="font-weight-bold">{String(educationData.rollNo ? educationData.rollNo : "--")} </span>
                    </div> : ""}
                    <div>
                        <span> University: </span> <span className="font-weight-bold">{String(educationData.university ? educationData.university : "--")} </span>
                    </div>


                </div>
                <div>
                    <div>
                        {/* <span> Reg No: </span> <span className="font-weight-bold">{String(educationData.registerationNumber)} </span> */}
                    </div>
                    <div><span>Certificate</span> {educationData.certificate ? <FontAwesomeIcon icon={faCheck} className="text-success" /> : <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />} </div>
                </div>
            </div>
        )
    }


    return (
        <div>
            <div>
                {educationInfo.map((data) => {
                    return (
                        <>
                            {renderEducationData(data)}
                        </>
                    )
                })}
            </div>
        </div>
    )
}