import { faArrowLeft, faCheck, faEye, faTimesCircle, faDownload, faEdit } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { Container, Card, Row, Col, Image, Table, Modal, Form, Button } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { getTransactionDetailsById, getUserProfile, updateCandidateEmail } from '../../services/admin';

import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { statusColors } from '../../services/statusColors';

import ViewEduInfo from './ViewEduInfo';
import TransactionModal from '../../components/Modal/TransactionModal';
import DownloadFile from '../../components/DownloadFile';


export default function ViewUser() {
    const { userid } = useParams();

    const [userData, setUserData] = useState();
    const [loaded, setLoaded] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [transactionDetails, setTransactionDetails] = useState();

    const [showUpdateModal, setShowUpdateModal] = useState(undefined);


    let history = useHistory();

    const onMount = async () => {
        await getUserProfile(userid).then(res => {
            if (res.status === 200) {
                toast.success("Data Loaded")
                setUserData(res.data);
                setLoaded(true)
            }
            else {
                toast.error("something went wrong")
            }
        }).catch(e => {
            toast.error("Something went wrong")
        })

    }

    const onMountTransaction = async () => {
        await getTransactionDetailsById(userid).then((res) => {
            if (res.status === 200) {
                setTransactionDetails(res.data);
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const createDateFormat = (date) => {
        return moment(date).format("DD-MM-YYYY")
    }


    const createStatus = (status) => {
        let color = statusColors(status);
        return <span class={"badge badge-" + color}>{status}</span>
    }

    const handleUpdateEmail = async () => {
        await updateCandidateEmail(userid, showUpdateModal).then((res) => {
            if (res.status == 200) {
                toast.success("Email Updated")
                setShowUpdateModal(undefined)
                onMount();
            }
        })
    }


    useEffect(() => {
        onMount()
        onMountTransaction();
    }, [])
    return (
        <>
            <Modal show={showUpdateModal ? true : false} onHide={() => setShowUpdateModal(undefined)} size="lg">
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" value={showUpdateModal} onChange={(e) => setShowUpdateModal(e.target.value)} />
                    </Form.Group>
                    <Button onClick={handleUpdateEmail}>
                        Update Email
                    </Button>
                </Modal.Body>
            </Modal>

            <div className="bg-bg-primary" style={{ minHeight: '100vh' }}>
                <Container className='pt-4'>
                    <Card className="p-3 border-0 rounded">
                        <FontAwesomeIcon icon={faArrowLeft} onClick={() => history.goBack()} />
                        {
                            loaded ?
                                <div className="mt-4">
                                    <h4 className="font-weight-bold">
                                        <div> Candidate Info (Reg. No. {userData && userData.registerationNumber})</div>

                                    </h4>
                                    <Row className="my-4">
                                        <Col>
                                            <div className="font-weight-bold">
                                                <span className="text-muted">
                                                    Name: {" "}
                                                </span>
                                                <span>
                                                    {userData && userData.name} {" "} {createStatus(userData && userData.status)}
                                                </span>
                                            </div>
                                        </Col>

                                        <Col>
                                            <div className="font-weight-bold">
                                                <span className="text-muted">
                                                    Application Number: {" "}
                                                </span>
                                                <span>
                                                    {userData && userData.applicationNumber ? userData.applicationNumber : "---"}
                                                </span>
                                            </div>
                                        </Col>

                                        <Col>
                                            <div className="font-weight-bold">
                                                <span className="text-muted">
                                                    Mobile Number: {" "}
                                                </span>
                                                <span>
                                                    {userData && userData.phone}
                                                </span>
                                            </div>
                                        </Col>

                                    </Row>
                                    <hr />
                                    <Row className="mt-4">
                                        <Col>
                                            <div className="font-weight-bold">
                                                <span className="text-muted">
                                                    Home State: {" "}
                                                </span>
                                                <span>
                                                    {userData && userData.homeState}
                                                </span>
                                            </div>
                                        </Col>

                                        <Col>
                                            <div className="font-weight-bold">
                                                <span className="text-muted">
                                                    Date of Birth: {" "}
                                                </span>
                                                <span>
                                                    {createDateFormat(userData && userData.dob)}
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <div className="font-weight-bold">
                                                <span className="text-muted">
                                                    Email: {" "}
                                                </span>
                                                <span>
                                                    {userData && userData.email}
                                                </span>
                                                <span>
                                                    <FontAwesomeIcon icon={faEdit} className="ml-2 text-muted" onClick={() => setShowUpdateModal(userData.email)} />
                                                </span>
                                            </div>
                                        </Col>

                                        <Col>
                                            <div className="font-weight-bold">
                                                <span className="text-muted">
                                                    Gender: {" "}
                                                </span>
                                                <span>
                                                    {userData && userData.gender}
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="font-weight-bold">
                                                <span className="text-muted">
                                                    Category: {" "}
                                                </span>
                                                <span>
                                                    {userData && userData.category?.toUpperCase()}
                                                </span>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="font-weight-bold">
                                                <span className="text-muted">
                                                    Aadhar Number: {" "}
                                                </span>
                                                <span>
                                                    {userData && userData.aadhar?.toUpperCase()}
                                                </span>
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="font-weight-bold">
                                                <span className="text-muted">
                                                    Person with Disability: {" "}
                                                </span>
                                                <span>
                                                    {userData && userData.pwd === true ? "Yes" : "No"}
                                                </span>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="font-weight-bold">
                                                <span className="text-muted">
                                                    Father Name: {" "}
                                                </span>
                                                <span>
                                                    {userData && userData.fatherName}
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <div className="font-weight-bold">
                                                <span className="text-muted">
                                                    Alternate Number: {" "}
                                                </span>
                                                <span>
                                                    {userData && userData.alternatePhone}
                                                </span>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="font-weight-bold">
                                                <span className="text-muted">
                                                    Created At: {" "}
                                                </span>
                                                <span>
                                                    {userData && moment(userData.createdAt).format("DD-MM-YYYY hh:mm a")}
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="font-weight-bold">
                                                <span className="text-muted">
                                                    disablity_type: {" "}
                                                </span>
                                                <span>
                                                    {userData && userData.disablity_type}
                                                </span>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="font-weight-bold">
                                                <span className="text-muted">
                                                    Last Updated At: {" "}
                                                </span>
                                                <span>
                                                    {userData && moment(userData.updatedAt).format("DD-MM-YYYY hh:mm a")}
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>

                                </div>
                                : " Loading... "}
                    </Card>
                </Container>

                <Container className='pt-4'>
                    <Card className="p-3 border-0 rounded">


                        <div>
                            <h4 className="font-weight-bold">
                                <div> Address 2</div>
                            </h4>


                            <Row>

                                {
                                    userData && userData.address && userData.address.temporary &&
                                    <Col md={6}>
                                        <h5 className="font-weight-bold">
                                            Current Address
                                        </h5>
                                        <div><span>Area</span> : {userData.address.temporary.area}</div>
                                        <div><span>City</span> : {userData.address.temporary.city}</div>
                                        <div><span>House Number</span>: {userData.address.temporary.houseNumber}</div>
                                        <div><span>Pincode </span>: {userData.address.temporary.pincode}</div>
                                        <div><span>State </span>: {userData.address.temporary.state}</div>
                                    </Col>
                                }

                                {
                                    userData && userData.address && userData.address.permanent &&

                                    <Col md={6}>
                                        <h5 className="font-weight-bold">
                                            Permanent Address
                                        </h5>
                                        <div><span>Area</span> : {userData.address.permanent.area}</div>
                                        <div><span>City</span> : {userData.address.permanent.city}</div>
                                        <div><span>House Number</span>: {userData.address.permanent.houseNumber}</div>
                                        <div><span>Pincode </span>: {userData.address.permanent.pincode}</div>
                                        <div><span>State </span>: {userData.address.permanent.state}</div>
                                    </Col>
                                }
                            </Row>
                        </div>
                    </Card>
                </Container>

                <Container className='pt-4'>
                    <Card className="p-3 border-0 rounded">
                        <h5 className="font-weight-bold">
                            Education Information
                        </h5>

                        <div>
                            {
                                userData && userData.educationQualification && userData.educationQualification.length > 0 ? <ViewEduInfo educationInfo={userData.educationQualification} /> : "No Data Avaliable"
                            }
                        </div>
                    </Card>
                </Container>
                <Container className='pt-4'>

                    <Card className="p-3 border-0 rounded">
                        <h5 className="font-weight-bold">
                            City Choices
                        </h5>

                        <div>
                            {
                                userData && userData.cityChoices && userData.cityChoices.length > 0 ? userData.cityChoices.map((data, index) => {
                                    return (
                                        <>
                                            {<span className="mr-3"> Choice {index + 1}:  <span className="font-weight-bold">{(data)}</span> </span>}
                                        </>
                                    )
                                }) : "User yet to submit Center Preferences"
                            }
                        </div>
                    </Card>
                </Container>

                <Container className='pt-4 pb-5'>

                    <Card className="p-3 border-0 rounded">
                        <h5 className="font-weight-bold">
                            Uploads
                        </h5>

                        <div className="d-flex justify-content-around mt-3 align-items-center">
                            <DownloadFile url={userData && userData.photo} name="Photo" />
                            <DownloadFile url={userData && userData.sign} name="Sign" />
                            <DownloadFile url={userData && userData.identityProof} name="Aadhar Front" />
                            <DownloadFile url={userData && userData.aadharBack} name="Aadhar Back" />
                            <DownloadFile url={userData && userData.bankInfo} name="Bank Passbook" />
                        </div>

                        <h5 className="font-weight-bold mt-5">
                            Special Certificates
                        </h5>
                        <div className="d-flex justify-content-around mt-3 align-items-center">
                            <DownloadFile url={userData && userData.pwdCertificate} name="PWD Certificate" />
                            <DownloadFile url={userData && userData.category_certificate} name="Category Certificate" />
                        </div>

                    </Card>
                </Container>
                <Container className='pt-4 pb-5'>

                    <Card className="p-3 border-0 rounded">
                        <h5 className="font-weight-bold">
                            All Transactions
                        </h5>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Exam Id</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th>Date</th>
                                    <th>Remaks</th>
                                    <th>Photo</th>
                                </tr>
                            </thead>
                            <tbody>
                                {transactionDetails && transactionDetails.length > 0 ? transactionDetails.map((transaction, index) => {
                                    return (
                                        <>
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{transaction.exam}</td>
                                                <td>{transaction.amount}</td>
                                                <td>{transaction.status}</td>
                                                <td>{createDateFormat(transaction && transaction.createdAt)}</td>
                                                <td>{transaction.remarks}</td>
                                                <td>
                                                    <a href={transaction.screenshot} download>
                                                        <FontAwesomeIcon icon={faDownload} className="text-primary" />
                                                    </a>
                                                </td>
                                            </tr>
                                        </>
                                    )
                                }) : ""}
                            </tbody>
                        </Table>
                    </Card>
                </Container>
            </div>
        </>
    )
}