import "./App.css";
import {
  BrowserRouter as Router,
} from "react-router-dom";
import RouterComp from "./Router";
import "react-datepicker/dist/react-datepicker.css";

function App() {
  return (
    <div className="App">
      <Router>
        <RouterComp />
      </Router>
    </div>
  );
}

export default App;
