import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { CSVDownload, CSVLink } from 'react-csv';

import { getCitiesData, getCandidatesByCenters } from '../../../services/admin';

import DataTable from '../DataTable/DataTable';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';


export default function ViewRecordByCenters() {
    const [userData, setUserData] = useState({
        users: []
    });

    const [downloadCsvFormat, setDownloadCsvFormat] = useState([]);

    const [selectedCityOne, setSelectedCityOne] = useState();
    const [selectedCityTwo, setSelectedCityTwo] = useState();

    const [allCities, setAllCities] = useState([]);


    const createDownloadCSVFormat = (statusdata) => {
        let csvFileData;
        if (statusdata && statusdata.users.length > 0) {
            csvFileData = statusdata.users.map((data, index) => {
                return {
                    Name: data.name,
                    "Registration Number": data.registerationNumber,
                    Email: data.email,
                    Phone: data.phone,
                    Status: data.status,
                    "City 1": data.cityChoices[0] || "Not Selected",
                    "City 2": data.cityChoices[1] || "Not Selected",
                    "City 3": data.cityChoices[2] || "Not Selected",
                    "Date of Birth": data.dob ? moment(data.dob).format("Do MMMM YYYY") : "Not Available",
                    "Fee Amount": data.transactionDetails?(data.transactionDetails.currency+" "+String(data.transactionDetails.amount/100)):"0",
                    "Application Number": data.applicationNumber,
                    "Transaction Id": data.transactionDetails? (data.transactionDetails.orderId):"NA",
                    "Trans. Date and Time": data.transactionDetails? moment(data.transactionDetails.updatedAt).format("Do MMMM YYYY hh:mm:ss a"):"NA",
                }

            });
        }
        setDownloadCsvFormat(csvFileData);
    }


    const onMount = async () => {
        await getCitiesData().then(res=>{
            if(res.status === 200){
                setAllCities(res.data);
            }
            else{
                toast.error("Something went wrong while fetching Cities")
            }
        }).catch(e=>{
            toast.error(e.response.data);
            console.error(e);
        });
    }


    useEffect(()=>{
        onMount();
    }, [])

    const changeSelectedCity = async (option, status) => {
        if(option === 1) {
            setSelectedCityOne(status.value)
        }
        if(option ===2){
            setSelectedCityTwo(status.value);
        }
        
    }

    const fetchUserData = async () =>{
        await toast.promise(getCandidatesByCenters(selectedCityOne, selectedCityTwo).then(res => {
            if (res.status === 200) {
                setUserData(res.data)
                createDownloadCSVFormat(res.data);
            }
            else {
                toast.error("Something went wrong")
            }
        }).catch(e => {
            console.error(e);
        }), {
            pending: "Fetching Records",
            success: 'Records Fetched 👌',
            error: 'Request Rejected 🤯'
        })
    }


    useEffect(()=>{
        
        if(selectedCityOne){
            fetchUserData();
        }

    }, [selectedCityOne, selectedCityTwo])

    return (
        <>

            <Container className="mt-4 ">
                <Row>
                    <Col md={4}>
                    <label className="font-weight-bold">Option 1</label>
                        <Select
                            options={allCities}
                            onChange={(e)=>changeSelectedCity(1,e)}
                        />
                    </Col>
                    <Col md={4}>
                        <label className="font-weight-bold">Option 2</label>
                        <Select
                            options={allCities}
                            onChange={(e)=>changeSelectedCity(2, e)}
                        />
                    </Col>
                    <Col md={4}>
                    <label className="font-weight-bold">Download</label>    
                         <div>
                        {downloadCsvFormat && downloadCsvFormat.length>0?
                        <CSVLink
                            data={downloadCsvFormat}
                            filename={"candidateData.csv"}
                        >
                            <Button> CSV <FontAwesomeIcon icon={faDownload}/> </Button> 
                        </CSVLink>
                        : <Button variant="secondary" disabled>CSV <FontAwesomeIcon icon={faDownload}/> </Button>}
                             </div>   

                    </Col>
                </Row>
                <Row className="bg-white mt-3 pt-2 rounded">
                    <Col>
                        {
                            userData && userData.users ?
                                <DataTable rows={userData.users} showStatus={false} showApplicationNumber={true} heading="User Data" /> : "No Data"

                        }
                    </Col>
                </Row>

            </Container>

        </>
    )
}