import { faDownload, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function DownloadFile({ url, name }) {
    return (
        <div className="text-center">
            {url ?
                <a href={url}>
                    <FontAwesomeIcon icon={faDownload} className="text-success" />
                </a>
                : <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />}
            <div className="text-center font-weight-bold mt-2">
                {name}
            </div>
        </div>
    )
}