import React, { useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { toast } from 'react-toastify';
import { updateRejectedPaymentForUser } from '../../services/admin';
import CustomModal from './Modal'



const PaymentRemarksModal = ({ show, setShow }) => {


    const [remarks, setRemarks] = useState();

    const updatePayment = async (e) => {
        e.preventDefault();
        await updateRejectedPaymentForUser(show, remarks)
            .then((res) => {
                if (res.status === 200) {
                    toast.success("Update successfully");
                    setShow(undefined)
                }
            })
            .catch((err) => {
                toast.error(err.response);
            });
    }


    return (
        <>
            <CustomModal
                displayModal={show ? true : false}
                onClose={() => setShow(undefined)}
                modalBodyClass="px-2"
                removeClose={true}
                modalBody={
                    <>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Remarks</Form.Label>
                            <Form.Control type="text" name="remarks" placeholder="Enter Remarks" onChange={(e) => setRemarks(e.target.value)} />
                        </Form.Group>
                        <Button variant="primary" type="submit" className="mt-3" onClick={updatePayment}>
                            Submit
                        </Button>
                        <Button variant="danger" className="mt-3" onClick={() => setShow(undefined)} style={{ marginLeft: "10px" }}>Close</Button>
                    </>
                }
            />

        </>
    )
}

export default PaymentRemarksModal