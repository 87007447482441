import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Container,
  Form,
  FormControl,
  Table,
} from "react-bootstrap";
import { toast } from "react-toastify";
import XadmDropdown from "../../components/Dropdown/Dropdown";
import PaymentRemarksModal from "../../components/Modal/PaymentRemarksModal";
import {
  getAllPaymentsByStatus,
  updateAcceptedPaymentStatus,
} from "../../services/admin";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import PaymentAcceptModal from "../../components/Modal/PaymentAcceptModal";
import { CSVDownload, CSVLink } from "react-csv";
import PaymentStatusModal from "../../components/Modal/PaymentStatus.modal";

const PaymentBox = (props) => {
  const searchRef = useRef();
  const [tableData, setTableData] = useState([]);
  const [searchTableData, setSearchTableData] = useState([]);
  const [status, setStatus] = useState("pending");
  const [showRemarksModal, setShowRemarksModal] = useState(undefined);
  const [showAcceptModal, setShowAcceptModal] = useState(undefined);
  const [downloadCsvFormat, setDownloadCsvFormat] = useState([]);


  const [showPaymentRejectedStatusModal, setShowPaymentRejectedStatusModal] = useState(undefined);
  const [showPaymentAcceptedStatusModal, setShowPaymentAcceptedStatusModal] = useState(undefined);


  const getAllPaymentsByStatusDetail = async () => {
    await getAllPaymentsByStatus(status)
      .then((res) => {
        if (res?.status === 200) {
          setTableData(res?.data);
          setSearchTableData(res?.data);
          createDownloadCSVFormat(res?.data);
        }
      })
      .catch((err) => {
        toast.error(err.response);
      });
  };

  useEffect(() => {
    getAllPaymentsByStatusDetail();
  }, [status, showRemarksModal, showAcceptModal]);

  const handlePaymentStatus = (value) => {
    setStatus(value);
  };

  const createDownloadCSVFormat = (csvData) => {
    let csvFileData;
    if (csvData && csvData.length > 0) {
      csvFileData = csvData.map((data, index) => {
        return {
          Status: data.status,
          "Payment Verification Id": data._id,
          "Registration Number": data.registerationNumber,
          Attachement: data.screenshot,
          Amount: data.amount,
          "Created At": moment(data.createdAt).format("DD-MM-YYYY hh:mm a"),
          "Updated At": moment(data.createdAt).format("DD-MM-YYYY hh:mm a"),
          "Transaction Id": data.transactionId,
          "Transaction Date": data.transactionDate
            ? moment(data.transactionDate).format("DD-MM-YYYY hh:mm a")
            : " --",
          "Action By": data.actionBy ? data.actionBy.email : " -- ",
          "Action On": data.actionOn
            ? moment(data.actionOn).format("DD-MM-YYYY hh:mm a")
            : " -- ",
        };
      });
    }
    setDownloadCsvFormat(csvFileData);
  };

  const searchData = (query) => {
    if (query) {
      const searchResult = tableData.filter((obj) =>
        JSON.stringify(obj).toLowerCase().includes(query.toLowerCase())
      );
      setSearchTableData(searchResult);
    } else {
      setSearchTableData(tableData);
    }
  };

  return (
    <>
      <Container className="mt-4">
        <div style={{ marginRight: "10rem" }}>
          <Card className="p-5 border-0" style={{ borderRadius: "10px", width: "93rem", right: "10rem" }}>
            <h5 className="mb-4">Payment Status</h5>
            <div className="d-flex justify-content-between mb-4">
              <div>
                <label className="font-weight-bold">Download</label>
                <div>
                  {downloadCsvFormat && downloadCsvFormat.length > 0 ? (
                    <CSVLink
                      data={downloadCsvFormat}
                      filename={"payment-data.csv"}
                    >
                      <Button>
                        CSV <FontAwesomeIcon icon={faDownload} />
                      </Button>
                    </CSVLink>
                  ) : (
                    <Button variant="secondary" disabled>
                      CSV <FontAwesomeIcon icon={faDownload} />
                    </Button>
                  )}
                </div>
              </div>
              <Form
                onSubmit={(e) => {
                  searchData(e.target[0].value);
                  e.preventDefault();
                }}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px",
                }}
                className="search-bar"
              >
                <FormControl
                  type="text"
                  ref={searchRef}
                  placeholder="Search"
                  className="mr-sm-2 search-input"
                  style={{
                    width: "60%",
                    borderRadius: "10px",
                    border: "none",
                    padding: "6px 10px",
                    fontSize: "0.9rem",
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
                    marginRight: "10px",
                  }}
                />
                <Button
                  type="submit"
                  variant="primary"
                  className="search-button"
                  style={{
                    borderRadius: "10px",
                    border: "none",
                    padding: "10px 10px",
                    fontSize: "0.9rem",
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
                    fontWeight: "500",
                  }}
                >
                  Search
                </Button>
                <Button
                  variant="primary"
                  className="search-button ml-2"
                  style={{
                    borderRadius: "10px",
                    border: "none",
                    padding: "10px 10px",
                    fontSize: "0.9rem",
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
                    fontWeight: "500",
                  }}
                  onClick={() => {
                    searchRef.current.value = "";
                    searchData("");
                  }}
                >
                  Reset
                </Button>
              </Form>
              <XadmDropdown handlePaymentStatus={handlePaymentStatus} />
            </div>
            <Table striped border hover responsive size="lg">
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th>Reg No.</th>
                  <th> Name</th>
                  <th> Email</th>
                  <th> Category</th>
                  <th> Aadhar No.</th>
                  <th> DOB</th>
                  <th>Amount</th>
                  <th> Proof </th>
                  {status === "pending" && <th> Accept/Reject</th>}
                  {status === "rejected" && <th> Remarks </th>}
                  {status === "completed" && (
                    <>
                      <th> Transaction Id </th>
                      <th> Transaction Date </th>
                    </>
                  )}
                  {(status === "completed" || status === "rejected") && (
                    <>
                      <th>Action By</th>
                      <th>Action On</th>
                    </>
                  )}
                  {(status === "completed" || status === "rejected") && (
                    <>
                      <th>Action</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {searchTableData && searchTableData.length > 0
                  ? searchTableData?.map((data, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td className="fs-7 font-weight-bold">
                          {data?.registerationNumber || "--"}
                        </td>
                        <td className="fs-7">{data?.user?.name || "--"}</td>
                        <td className="fs-7">{data?.user?.email || "--"}</td>
                        <td className="fs-7">
                          {data?.user?.category.toUpperCase() || "--"}
                        </td>
                        <td className="fs-7">{data?.user?.aadhar || "--"}</td>
                        <td className="fs-8 text-nowrap">
                          {moment(data?.user?.dob).format("DD-MM-YYYY") || "--"}
                        </td>
                        <td className="fs-8 font-weight-bold">
                          Rs. {data?.amount}
                        </td>
                        <th>
                          <a href={data.screenshot}>
                            <FontAwesomeIcon
                              icon={faDownload}
                              className="text-info"
                            />
                          </a>
                        </th>
                        {status === "pending" && (
                          <td className="d-flex justify-content-start">
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => {
                                setShowAcceptModal(data._id);
                              }}
                            >
                              Accept
                            </Button>
                            <Button
                              variant="danger"
                              size="sm"
                              className="ml-2"
                              onClick={() => {
                                setShowRemarksModal(data._id);
                              }}
                            >
                              Reject
                            </Button>
                          </td>
                        )}
                        {status === "rejected" && (
                          <td className="fs-7 font-weight-bold">
                            {data?.remarks || "--"}
                          </td>
                        )}
                        {status === "completed" && (
                          <>
                            <td className="fs-7">
                              {data?.transactionId || "--"}
                            </td>
                            <td className="fs-7">
                              {moment(data?.transactionDate).format(
                                "DD-MM-YYYY hh:mm a"
                              ) || "--"}
                            </td>
                          </>
                        )}
                        {(status === "completed" || status === "rejected") && (
                          <>
                            <td className="fs-7">
                              {data?.actionBy?.email || "--"}
                            </td>
                            <td className="fs-7">
                              {moment(data?.actionOn).format(
                                "DD-MM-YYYY hh:mm a"
                              ) || "--"}
                            </td>
                          </>
                        )}
                        {status === "completed" ?
                          <div>
                            <Button onClick={() => setShowPaymentAcceptedStatusModal(data && data._id)} variant="secondary">Reject accepted payment</Button>
                          </div> : status === "rejected" && < div >
                            <Button onClick={() => setShowPaymentRejectedStatusModal(data && data._id)}>Accept rejected payment</Button>
                          </div>}
                      </tr>
                    );
                  })
                  : "No data found"}
              </tbody>
            </Table>
          </Card>
        </div>

        <PaymentRemarksModal
          show={showRemarksModal}
          setShow={setShowRemarksModal}
        />

        <PaymentAcceptModal
          show={showAcceptModal}
          setShow={setShowAcceptModal}
        />

        <PaymentStatusModal
          show={showPaymentRejectedStatusModal}
          setShow={setShowPaymentRejectedStatusModal}
          reload={getAllPaymentsByStatusDetail}
          isReject={true}
        />

        <PaymentStatusModal
          show={showPaymentAcceptedStatusModal}
          setShow={setShowPaymentAcceptedStatusModal}
          reload={getAllPaymentsByStatusDetail}
          isReject={false}
        />




      </Container>
    </>
  );
};

export default PaymentBox;
